import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt-br";
import { capitalize } from "lodash";
import { getLang, l } from "./Lang";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 3 });
axios.defaults.withCredentials = true;

const DEV_ENV = false;

export const REFRESH_TIME = DEV_ENV ? 999999999 : 30000;

export const MAX_TIME_MS = 120000;
const STATUS_LABELS = [
  l("statusOperative"),
  l("statusUnstable"),
  l("statusDelays"),
];
export const STATUS_COLOR_TEXTS = ["#44A134", "#DD9320", "#D0021B"];
export const STATUS_COLOR_BAR_BACKGROUND = ["#E6F9F0", "#FAE6E8", "#FAE6E8"];
export const STATUS_COLOR_BAR_BORDER = ["#02C66A", "#FB1531", "#FB1531"];
export const STATUS_COLOR = ["#3dc639", "#FEEDD3", "#F8CCD1"];

const enrich = (channelIn) => {
  const { platform, platformId, channelName } = channelIn;
  let link = "";
  let name = getPlatformName(platform);

  if (platform === "whatsapp") {
    name = platformId;
    link = `https://wa.me/${platformId}`;
  } else if (platform === "messenger") {
    name = channelName || platformId;
    link = `https://m.me/${platformId}`;
  } else if (platform === "webchat") {
    name = channelName || platformId;
    link = `https://storage.googleapis.com/m-infra.appspot.com/public/botmaker/webChatTest.html?id=${platformId}`;
  }
  // if (platform === 'telegram') icon = 'telegram';
  // if (platform === 'twitter') icon = 'twitter';
  // if (platform === 'twitter_tweets') icon = 'twitter';

  // if (platform === 'facebook-page') icon = 'facebook';
  // if (platform === 'sms') icon = 'sms';

  channelIn.name = name;
  channelIn.platformName = getPlatformName(platform);
  channelIn.link = link;
};

const asc = (arr) => arr.sort((a, b) => a - b);

export const quantile = (arr, q) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;

  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const getStatus = (duration, optProblemsValue) => {
  if (optProblemsValue)
    return duration < 0 || duration > optProblemsValue ? "down" : "operational";

  if (duration > 55000) return "down";

  return duration < 10000 ? "operational" : "unstable";
};

export const formatDate = (date) => {
  return moment(date).format("DD-MM-YYYY HH:mm");
};

export const formatDateHHMM = (date) => {
  return moment(date).format("HH:mm");
};

const getLocalizedMomentDate = (isoDate) => {
  moment.locale(getLang() === "pt" ? "pt-br" : getLang());
  return moment(isoDate).add(3, "hours");
};

export const formatDateDDMMYYYHHSS = (isoDate) => {
  const date = getLocalizedMomentDate(isoDate);
  return `${date.format("L")} ${date.format("LT")}`;
};

export const formatDateDDMMYYYY = (isoDate) => {
  return getLocalizedMomentDate(isoDate).format("L");
};

export const fromDate = (date) => {
  const dateText = moment(date).fromNow();
  return dateText.charAt(0).toUpperCase() + dateText.slice(1);
};

export const formatDateHistoryTitle = (isoDate) => {
  return getLocalizedMomentDate(isoDate).format("LL");
};

export const formatDateHistoryeEntry = (isoDate) => {
  const date = getLocalizedMomentDate(isoDate);
  return `${date.format("LL")} ${date.format("LT")} GMT-3`;
};

export const getPlatformName = (platformId) => {
  if (platformId === "whatsapp") return "WhatsApp";
  if (platformId === "messenger") return "FB Messenger";
  if (platformId === "facebook-page") return "FB Page";
  if (platformId === "twitter_tweets") return "Tweets";

  return capitalize(platformId);
};

// export const formatHour = (date) => {
//     return 'a las ' + moment(date).format('HH:mm');
// }

export const getAuth = async (authToken, desiredBid, lang) => {
  return axios.post(`${getHost()}/auth`, { authToken, desiredBid, lang });
};

const getHost = () => {
  if (DEV_ENV) return "https://status.botmaker.com";

  return window.location.hostname === "localhost"
    ? "http://localhost:8080"
    : "https://status.botmaker.com";
};

// export const preparePairDataPoints = (pointsArray, otherPointsArray, maxAxisY) => {
//     const otherPointsMap = (otherPointsArray || [])
//         .reduce((prev, curr) => {
//             const time = moment(curr.iso).format('HH:mm');
//
//             prev[time] = curr;
//
//             return prev;
//         }, {});
//
//     return (pointsArray || [])
//         .map(p => {
//             const time = moment(p.iso).format('HH:mm');
//             const userValueAtTime = otherPointsMap[time] || {v: 0};
//
//             return {
//                 time,
//                 value1: p.v < 0 || p.v > maxAxisY ? maxAxisY : p.v,
//                 value2: userValueAtTime.v < 0 || userValueAtTime.v > maxAxisY ? maxAxisY : userValueAtTime.v
//             };
//         });
// };

export const prepareDataPoints = (pointsArray, maxAxisY) => {
  return (pointsArray || []).map((p) => {
    const time = moment(p.iso).format("HH:mm");

    return {
      time,
      value1: p.v < 0 || p.v > maxAxisY ? maxAxisY : p.v,
    };
  });
};

export const getMax = (chartPoints) => {
  let max = 0;

  chartPoints.forEach((p) => {
    const v = Math.max(p.value1 || 0, p.value2 || 0);

    if (v > max) max = v;
  });

  return Math.round(parseInt(max));
};

export const getMetrics = async (businessId, lang, optAuthToken) => {
  const raw = await axios.post(`${getHost()}/metrics`, {
    desiredBid: businessId,
    authToken: optAuthToken || null,
    lang,
  });

  const result = raw.data;
  result.lastRefreshTime = new Date();

  (result.channelMetrics || []).forEach((v) => enrich(v));

  return result;
};

const MOCK_HISTORY_LOGS_DATA = {
  data: {
    ChangeLog: [
      {
        CHANGES_EN: "Top Navigation v3.01",
        DATE: "2024-10-22T09:48:00.000Z",
        ENTRY: "1",
        VERSION_EN: "Flash version 1.1",
        CHANGES_PT: "Navegação superior v3.01",
        VERSION_ES: "Versión Flash 1.1",
        VERSION_PT: "Flash Versão 1.1",
        CHANGES_ES: "Navegación superior v3.01",
      },
      {
        CHANGES_EN: "Tag v1.04: New Variant - Borderless",
        ENTRY: "1",
        VERSION_EN: "",
        CHANGES_PT: "Tag v1.04: Nova Variante - Sem Bordas",
        VERSION_ES: "",
        VERSION_PT: "",
        CHANGES_ES: "Etiqueta v1.04: Nueva variante: sin bordes",
      },
      {
        CHANGES_EN: "Top Navigation v3.0",
        DATE: "2024-10-20T17:20:12.000Z",
        ENTRY: "2",
        VERSION_EN: "Flash version 1.0",
        CHANGES_PT: "Navegação superior v3.0",
        VERSION_ES: "Versión Flash 1.0",
        VERSION_PT: "Flash Versão 1.0",
        CHANGES_ES: "Navegación superior v3.0",
      },
      {
        CHANGES_EN: "Tag v1.03: New Variant - Borderless",
        ENTRY: "2",
        VERSION_EN: "",
        CHANGES_PT: "Tag v1.03: Nova Variante - Sem Bordas",
        VERSION_ES: "",
        VERSION_PT: "",
        CHANGES_ES: "Etiqueta v1.03: Nueva variante: sin bordes",
      },
      {
        CHANGES_EN: "Top Navigation v2.9",
        DATE: "2024-10-18T17:20:12.000Z",
        ENTRY: "3",
        VERSION_EN: "Flash version 0.9",
        CHANGES_PT: "Navegação superior v2.9",
        VERSION_ES: "Versión Flash 0.9",
        VERSION_PT: "Flash Versão 0.9",
        CHANGES_ES: "Navegación superior v2.9",
      },
      {
        CHANGES_EN: "Tag v1.01: New Variant - Borderless",
        ENTRY: "3",
        VERSION_EN: "",
        CHANGES_PT: "Tag v1.01: Nova Variante - Sem Bordas",
        VERSION_ES: "",
        VERSION_PT: "",
        CHANGES_ES: "Etiqueta v1.01: Nueva variante: sin bordes",
      },
      {
        CHANGES_EN: "Top Navigation v2.8",
        DATE: "2024-10-17T17:20:12.000Z",
        ENTRY: "4",
        VERSION_EN: "Flash version 0.8",
        CHANGES_PT: "Navegação superior v2.8",
        VERSION_ES: "Versión Flash 0.8",
        VERSION_PT: "Flash Versão 0.8",
        CHANGES_ES: "Navegación superior v2.8",
      },
      {
        CHANGES_EN: "Top Navigation v2.7",
        DATE: "2024-10-15T17:20:12.000Z",
        ENTRY: "5",
        VERSION_EN: "Flash version 0.7",
        CHANGES_PT: "Navegação superior v2.7",
        VERSION_ES: "Versión Flash 0.7",
        VERSION_PT: "Flash Versão 0.7",
        CHANGES_ES: "Navegación superior v2.7",
      },
    ],
    History: [
      {
        TITLE_EN:
          "Users are experiencing issues with the API and login in Botmaker",
        DESCRIPTION_ES:
          "El 4 de julio de 2024, entre las 5:09 PM PDT y las 5:20 PM PDT, muchos clientes no pudieron enviar mensajes ni conectarse a Botmaker. Un cambio rutinario en el código introdujo un error en la base de datos que impidió que los datos en caché se borraran correctamente, lo que resultó en graves problemas de rendimiento.",
        DESCRIPTION_CHANGE_ES:
          "Revertimos el cambio de código y actualizamos todos los servidores afectados, resolviendo el problema para todos los usuarios.",
        ENTRY: "1",
        TITLE_ES:
          "Usuarios experimentan problemas con la API y el inicio de sesión en Botmaker",
        DATE_CHANGE: "2024-10-15T09:48:00.000Z",
        DESCRIPTION_CHANGE_PT:
          "Revertemos a alteração do código e atualizamos todos os servidores afetados, resolvendo o problema para todos os usuários.",
        DESCRIPTION_CHANGE_EN:
          "We have reverted the code change and updated all affected servers, resolving the issue for all users.",
        FEATURES: "Conectividad, Mensajes",
        DATE: "2024-10-15T14:48:00.000Z",
        STATUS: "OK",
        DESCRIPTION_PT:
          "Em 4 de julho de 2024, entre 17h09 PDT e 17h20 PDT, muitos clientes não conseguiram enviar mensagens ou se conectar ao Botmaker. Uma alteração rotineira no código introduziu um bug no banco de dados que impedia que os dados armazenados em cache fossem limpos corretamente, resultando em sérios problemas de desempenho.",
        TITLE_PT: "Os usuários enfrentam problemas de API e login no Botmaker",
        DESCRIPTION_EN:
          "On July 4th, 2024, between 5:09 PM PDT and 5:20 PM PDT, many clients were unable to send messages or connect to Botmaker. A routine code change introduced a bug into the database that prevented cached data from being properly cleared, resulting in severe performance issues.",
      },
      {
        TITLE_EN: "",
        DESCRIPTION_ES: "",
        DESCRIPTION_CHANGE_ES:
          "Hemos resuelto el problema que los usuarios experimentaron al intentar conectarse a Botmaker o enviar mensajes. Agradecemos su paciencia mientras solucionábamos esto y pedimos disculpas por cualquier interrupción en su día.",
        ENTRY: "1",
        TITLE_ES: "",
        DATE_CHANGE: "2024-10-14T14:48:00.000Z",
        DESCRIPTION_CHANGE_PT:
          "Resolvemos o problema que os usuários enfrentavam ao tentar se conectar ao Botmaker ou enviar mensagens. Agradecemos sua paciência enquanto resolvemos isso e pedimos desculpas por qualquer interrupção no seu dia.",
        DESCRIPTION_CHANGE_EN:
          "We have resolved the issue users experienced when trying to connect to Botmaker or send messages. We appreciate your patience while we worked through this and apologize for any disruption to your day.",
        DATE: "",
        STATUS: "",
        DESCRIPTION_PT: "",
        TITLE_PT: "",
        DESCRIPTION_EN: "",
      },
      {
        TITLE_EN: "",
        DESCRIPTION_ES: "",
        DESCRIPTION_CHANGE_ES:
          "Algunos usuarios han estado experimentando problemas para conectarse a Botmaker o enviar mensajes. Estamos observando un aumento en los errores de conectividad y nuestro equipo está investigando actualmente. Volveremos con otra actualización tan pronto como tengamos más información para compartir.",
        ENTRY: "1",
        TITLE_ES: "",
        DATE_CHANGE: "2024-10-13T14:48:00.000Z",
        DESCRIPTION_CHANGE_PT:
          "Alguns usuários têm enfrentado problemas para se conectar ao Botmaker ou enviar mensagens. Estamos vendo um aumento nos erros de conectividade e nossa equipe está investigando. Voltaremos com outra atualização assim que tivermos mais informações para compartilhar.",
        DESCRIPTION_CHANGE_EN:
          "Some users have been experiencing issues connecting to Botmaker or sending messages. We are seeing an increase in connectivity errors and our team is currently investigating. We will be back with another update as soon as we have more information to share.",
        DATE: "",
        STATUS: "",
        DESCRIPTION_PT: "",
        TITLE_PT: "",
        DESCRIPTION_EN: "",
      },
      {
        TITLE_EN: "Users cannot connect to Botmaker or send messages",
        DESCRIPTION_ES:
          "El 4 de julio de 2024, entre las 5:09 PM PDT y las 5:20 PM PDT, muchos clientes no pudieron enviar mensajes ni conectarse a Botmaker. Un cambio rutinario en el código introdujo un error en la base de datos que impidió que los datos en caché se borraran correctamente, lo que resultó en graves problemas de rendimiento.",
        DESCRIPTION_CHANGE_ES:
          "Hemos resuelto el problema que los usuarios experimentaron al intentar conectarse a Botmaker o enviar mensajes. Agradecemos su paciencia mientras solucionábamos esto y pedimos disculpas por cualquier interrupción en su día.",
        ENTRY: "2",
        TITLE_ES: "Usuarios no pueden conectarse a Botmaker o enviar mensajes",
        DATE_CHANGE: "2024-10-12T09:05:00.000Z",
        DESCRIPTION_CHANGE_PT:
          "Resolvemos o problema que os usuários enfrentavam ao tentar se conectar ao Botmaker ou enviar mensagens. Agradecemos sua paciência enquanto resolvemos isso e pedimos desculpas por qualquer interrupção no seu dia.",
        DESCRIPTION_CHANGE_EN:
          "We have resolved the issue users experienced when trying to connect to Botmaker or send messages. We appreciate your patience while we worked through this and apologize for any disruption to your day.",
        FEATURES: "Conectividad",
        DATE: "2024-10-12T09:05:00.000Z",
        STATUS: "ISSUES",
        DESCRIPTION_PT:
          "Em 4 de julho de 2024, entre 17h09 PDT e 17h20 PDT, muitos clientes não conseguiram enviar mensagens ou se conectar ao Botmaker. Uma alteração rotineira no código introduziu um bug no banco de dados que impedia que os dados armazenados em cache fossem limpos corretamente, resultando em sérios problemas de desempenho.",
        TITLE_PT:
          "Os usuários não podem se conectar ao Botmaker ou enviar mensagens",
        DESCRIPTION_EN:
          "On July 4th, 2024, between 5:09 PM PDT and 5:20 PM PDT, many clients were unable to send messages or connect to Botmaker. A routine code change introduced a bug into the database that prevented cached data from being properly cleared, resulting in severe performance issues.",
      },
      {
        TITLE_EN: "",
        DESCRIPTION_ES: "",
        DESCRIPTION_CHANGE_ES:
          "Algunos usuarios han estado experimentando problemas para conectarse a Botmaker o enviar mensajes. Estamos observando un aumento en los errores de conectividad y nuestro equipo está investigando actualmente. Volveremos con otra actualización tan pronto como tengamos más información para compartir.",
        ENTRY: "2",
        TITLE_ES: "",
        DATE_CHANGE: "2024-10-11T09:05:00.000Z",
        DESCRIPTION_CHANGE_PT:
          "Alguns usuários têm enfrentado problemas para se conectar ao Botmaker ou enviar mensagens. Estamos vendo um aumento nos erros de conectividade e nossa equipe está investigando. Voltaremos com outra atualização assim que tivermos mais informações para compartilhar.",
        DESCRIPTION_CHANGE_EN:
          "Some users have been experiencing issues connecting to Botmaker or sending messages. We are seeing an increase in connectivity errors and our team is currently investigating. We will be back with another update as soon as we have more information to share.",
        DATE: "",
        STATUS: "",
        DESCRIPTION_PT: "",
        TITLE_PT: "",
        DESCRIPTION_EN: "",
      },
      {
        TITLE_EN: "Title of a history log 3",
        DESCRIPTION_ES:
          "El 4 de julio de 2024, entre las 5:09 PM PDT y las 5:20 PM PDT, muchos clientes no pudieron enviar mensajes ni conectarse a Botmaker. Un cambio rutinario en el código introdujo un error en la base de datos que impidió que los datos en caché se borraran correctamente, lo que resultó en graves problemas de rendimiento.",
        DESCRIPTION_CHANGE_ES:
          "Revertimos el cambio de código y actualizamos todos los servidores afectados, resolviendo el problema para todos los usuarios.",
        ENTRY: "3",
        TITLE_ES: "Titulo de un history log 3",
        DATE_CHANGE: "2024-10-10T12:12:00.000Z",
        DESCRIPTION_CHANGE_PT:
          "Revertemos a alteração do código e atualizamos todos os servidores afetados, resolvendo o problema para todos os usuários.",
        DESCRIPTION_CHANGE_EN:
          "We have reverted the code change and updated all affected servers, resolving the issue for all users.",
        FEATURES: "Bots, Mensajes",
        DATE: "2024-10-10T12:12:00.000Z",
        STATUS: "ERROR",
        DESCRIPTION_PT:
          "Em 4 de julho de 2024, entre 17h09 PDT e 17h20 PDT, muitos clientes não conseguiram enviar mensagens ou se conectar ao Botmaker. Uma alteração rotineira no código introduziu um bug no banco de dados que impedia que os dados armazenados em cache fossem limpos corretamente, resultando em sérios problemas de desempenho.",
        TITLE_PT: "Título de um registro histórico 3",
        DESCRIPTION_EN:
          "On July 4th, 2024, between 5:09 PM PDT and 5:20 PM PDT, many clients were unable to send messages or connect to Botmaker. A routine code change introduced a bug into the database that prevented cached data from being properly cleared, resulting in severe performance issues.",
      },
    ],
  },
};

export const getHistoryLogs = async (businessId, optAuthToken) => {
  const raw = DEV_ENV
    ? MOCK_HISTORY_LOGS_DATA
    : await axios.post(`${getHost()}/history-logs`, {
        desiredBid: businessId,
        authToken: optAuthToken || null,
      });

  const result = {
    ChangeLog: generateLogsData(raw?.data?.ChangeLog),
    History: generateHistoryData(raw?.data?.History),
  };

  return result;
};

const updateFieldIfEmpty = (existing, newField, fieldName) => {
  if (newField && !existing[fieldName]) {
    existing[fieldName] =
      fieldName === "FEATURES"
        ? newField.split(", ").map((item) => item.trim())
        : newField;
  }
};

const generateLogsData = (logs) => {
  const result = [];

  (logs || []).forEach((item) => {
    let existingEntry = result.find((entry) => entry.ENTRY === item.ENTRY);

    if (!existingEntry) {
      existingEntry = {
        ENTRY: item.ENTRY,
        DATE: item.DATE,
        VERSION_EN: item.VERSION_EN,
        VERSION_ES: item.VERSION_ES,
        VERSION_PT: item.VERSION_PT,
        CHANGES: [],
      };
      result.push(existingEntry);
    } else {
      // In case first one has empty fields (not orderer).
      updateFieldIfEmpty(existingEntry, item.DATE, "DATE");
      updateFieldIfEmpty(existingEntry, item.VERSION_EN, "VERSION_EN");
      updateFieldIfEmpty(existingEntry, item.VERSION_ES, "VERSION_ES");
      updateFieldIfEmpty(existingEntry, item.VERSION_PT, "VERSION_PT");
    }

    existingEntry.CHANGES.push({
      CHANGES_EN: item.CHANGES_EN,
      CHANGES_ES: item.CHANGES_ES,
      CHANGES_PT: item.CHANGES_PT,
    });
  });

  return result;
};
const generateHistoryData = (history) => {
  const result = [];

  (history || []).forEach((item) => {
    let existingEntry = result.find((entry) => entry.ENTRY === item.ENTRY);

    if (!existingEntry) {
      existingEntry = {
        ENTRY: item.ENTRY,
        DATE: item.DATE,
        STATUS: item.STATUS,
        TITLE_EN: item.TITLE_EN,
        TITLE_ES: item.TITLE_ES,
        TITLE_PT: item.TITLE_PT,
        DESCRIPTION_EN: item.DESCRIPTION_EN,
        DESCRIPTION_ES: item.DESCRIPTION_ES,
        DESCRIPTION_PT: item.DESCRIPTION_PT,
        CHANGES: [],
        FEATURES: item.FEATURES
          ? item.FEATURES.split(", ").map((item) => item.trim())
          : "",
      };
      result.push(existingEntry);
    } else {
      // In case first one has empty fields (not orderer).
      updateFieldIfEmpty(existingEntry, item.DATE, "DATE");
      updateFieldIfEmpty(existingEntry, item.STATUS, "STATUS");
      updateFieldIfEmpty(existingEntry, item.TITLE_EN, "TITLE_EN");
      updateFieldIfEmpty(existingEntry, item.TITLE_ES, "TITLE_ES");
      updateFieldIfEmpty(existingEntry, item.TITLE_PT, "TITLE_PT");
      updateFieldIfEmpty(existingEntry, item.DESCRIPTION_EN, "DESCRIPTION_EN");
      updateFieldIfEmpty(existingEntry, item.DESCRIPTION_ES, "DESCRIPTION_ES");
      updateFieldIfEmpty(existingEntry, item.DESCRIPTION_PT, "DESCRIPTION_PT");
      updateFieldIfEmpty(
        existingEntry,
        item.FEATURES
          ? item.FEATURES.split(", ").map((item) => item.trim())
          : "",
        "FEATURES"
      );
    }

    existingEntry.CHANGES.push({
      DATE_CHANGE: item.DATE_CHANGE,
      DESCRIPTION_CHANGE_EN: item.DESCRIPTION_CHANGE_EN,
      DESCRIPTION_CHANGE_ES: item.DESCRIPTION_CHANGE_ES,
      DESCRIPTION_CHANGE_PT: item.DESCRIPTION_CHANGE_PT,
    });
  });

  return result;
};

export const formatDuration = (duration) => {
  if (duration === -1 || duration === 120000) return l("noReply");

  const durationAsSeconds = moment.duration(duration).asSeconds();
  const regex2decimals = /(\d*.\d{0,2})/;
  const durationResult = durationAsSeconds.toString().match(regex2decimals)[0];

  return l("reply") + " " + durationResult + " " + l("seconds");
};

export const formatMSasSeconds = (duration) => {
  const durationAsSeconds = moment.duration(duration).asSeconds();
  const regex2decimals = /(\d*.\d{0,2})/;
  const durationResult = durationAsSeconds.toString().match(regex2decimals)[0];

  return durationResult;
};

export const formatInteger = (value) => {
  const lang = getLang();

  return Number(value).toLocaleString(lang === "es" ? "es-AR" : lang);
};

export const getStatusRender = (type, v, optProblemsValue) => {
  const mode = getStatus(v, optProblemsValue);
  const index = mode === "operational" ? 0 : mode === "unstable" ? 1 : 2; // else 'down'

  if (type === "label") return STATUS_LABELS[index];

  if (type === "colorText") return STATUS_COLOR_TEXTS[index];

  return STATUS_COLOR[index];
};

export const getStatusByName = (state) => {
  switch (state) {
    case "OK":
      return 0;
    case "ISSUES":
      return 1;
    case "ERROR":
      return 2;
    default:
      return 0;
  }
};

// export const formatFromNow = (date) => moment(date).fromNow();
