import { Button, Icon } from "@mui/material";
import { l } from "../../Lang";
import "./BackButton.scss";

const BackButton = ({ onBack }: { onBack: () => void }) => {
  return (
    <Button variant="text" className="btn_back" onClick={onBack}>
      <Icon>west</Icon>
      <span className="btn_back_text">{l("btn_back")}</span>
    </Button>
  );
};

export default BackButton;
